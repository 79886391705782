@import "../../App.scss";

.header {
    background-color: var(--header-color);
    width: 100%;
    z-index: 5;
    height: 6vh;
    display: flex;
    padding-left: 5%;
    padding-right: 5%;
   
    .button-preview{
        min-width: 100px;
        height: 31px;
        color: white;
        border: none !important;
        font-family: $fontMedium;
        margin-top: auto;
        margin-bottom: auto;
        font-size: 13px;
        margin-right: 16px;
        background-color: var(--button-primary-color);

        @include media-breakpoint-down(md){
            margin-top: 36px;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
        }
    }

  

    .logo-branding {
        cursor: pointer;
        width: auto;
        height: 31px;
        object-fit: contain;
        margin-top: auto;
        margin-bottom: auto;

    }

    .login-control{
        display: flex;
        border-radius: 24px;
        margin-top: auto;
        margin-bottom: auto;
        border: 1px solid white;
        padding-left: 12px;
        padding-right: 12px;
        width: 150px;
        color: white;
        font-family: $fontMedium;
        justify-content: center;
        margin-right: 12px;
        background-color: var(--button-primary-color);
    }

  

    .menu-collapse {
        margin-top: 24px;
        font-size: 18px;
        color: white;
        font-family: $fontMedium;
        margin-left: 24px;

        .link-menu{
            
            cursor: pointer;
        }
    }

    .right-login-profile-control {
        border-radius: 24px;
        border: 0.5px solid white;
        margin-right: 12px;
        height: auto;
        margin-top: auto;
        margin-bottom: auto;
        display: flex;


        .right-login-profile-control-dropdown{
            background-color: transparent !important;
            border: none !important;
        }

        .right-profile-img {
            width: 30px;
            height: 30px;
            object-fit: contain;
            margin-right: 15px;

            @include media-breakpoint-down(md) {
                width: 20px;
                height: 20px;
            }
        }

        .right-profile-name {
            color: white;
            font-size: 12px;
            font-family: $fontMedium;
            margin-top: auto;
            margin-bottom: auto;
            @include media-breakpoint-down(md) {
                font-size: 14px;
            }
        }

        .right-dropdown {
            width: 15px;
            height: 15px;
            object-fit: contain;
            margin-left: 24px;
            margin-top: auto;
            margin-bottom: auto;

            
        }
    }

    
    .collapse-control {
        display: flex;
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;


    }
    
}