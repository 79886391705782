@import "../../App.scss";


.login-page {

    display: grid;
    grid-template-columns: 50% 50%;

    @include media-breakpoint-down(md){
        grid-template-columns: 100%;
    }

    .text-or{
        margin-top: 16px;
        margin-bottom: 16px;
        font-size: 16px;
        color: var(--text-color);
        font-family: $fontMedium;
    }

    .left-control {
        display: flex;
        width: 100%;

        @include media-breakpoint-down(md){
            display: none;
        }

        .left-img {
            width: 100%;
            height: 100vh;
            object-fit: cover;
        }

    }

    .right-control {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-down(md){
            height: 100vh;
        }

        .right-div-control {
            display: flex;
            flex-direction: column;
            margin-top: auto;
            margin-bottom: auto;
            width: 40%;
            margin-left: auto;
            margin-right: auto;

            @include media-breakpoint-down(lg){
                width: 90%;
            }

            .right-login-scoutout{
                background-color: var(--primary-red);
                color: white;
                font-family: $fontMedium;
                font-size: 17px;
                border-radius: 12px;
                border: none;
                width: 100%;
                min-height: 46px;
            }

            .right-img-branding {
                width: 100%;
                height: auto;
                object-fit: contain;
                margin-bottom: 60px;
            }

            .right-login-google {
                cursor: pointer;
                width: 100%;
                height: 55px;
                margin-top: 24px;
                object-fit: contain;
            }

            .right-login-text {
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                font-size: 16px;
                font-family: $fontMedium;
                color: black;
            }

            .right-login-error {
               
                font-size: 14px;
                font-family: $fontMedium;
                color: var(--primary-red);
            }

            .right-input-form {
                margin-top: 24px;
                height: 56px;
                width: 100%;
                padding: 4px 16px;
                border: 1.2px solid #B2BBBB;
                border-radius: 4px;
            }
            .right-input-link{
                font-size: 16px;
                font-family: $fontMedium;
                color: var(--button-primary-color);
                margin-left: auto;
                margin-top: 16px;
            }

            .right-input-button{
                margin-top: 34px;
                background: var(--primary-red);
                padding: 8px 24px;
                width: 100%;
                color: white;
                font-family: $fontMedium;
                border: none;
                font-size: 16px;
                border-radius: 4px;
                margin-left: auto;

                &:hover{
                    background-color: #f92929;
                }
            }
        }
    }
}