@import "../App.scss";

.search-note-modal{


    .search-note-header{
        font-size: 16px;
        font-family: $fontMedium !important;
        color: var(--text-color)
    }

    .search-note-input {
        border: 1.2px solid #B2BBBB;
        border-radius: 4px;
        min-height: 41px;
        width: 100%;
        font-family: $fontRegular;
        background-color: white;
        
        &::placeholder{
            font-size: 12px;
        }
        &:focus {
            border: 1.2px solid #B2BBBB;
            background-color: white;
        }

        @include media-breakpoint-down(md) {
            font-size: 14px;
        }
    }

    .search-note-button{
        width: 60%;
        height: 51px;
        color: white;
        margin-left: auto;
        margin-right: auto;
        font-size: 15px;
        font-family: $fontMedium;
        background-color: var(--button-primary-color);
        border-radius: 8px;
        border: none;
        margin-top: 24px;
    }
}