@import "../../App.scss";


.candidate-view-main{


    width: 100vw;
    min-height: 100vh;
    display: flex;
    padding: 2% 5%;

    @include media-breakpoint-down(md){
        padding: 12px 12px;
    }

    .candidate-view-item-control{
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid var(--primary-red);
        padding: 24px 48px;
        border-radius: 8px;
        box-shadow: 0px 0px 5px 2px var(--primary-red);

        @include media-breakpoint-down(md){
            width: 95%;
            padding: 12px;
        }
        @include media-breakpoint-down(sm){
            width: 98%;
            padding: 12px 24px;
        }


        .candidate-view-item{
            display: flex;
            margin-top: 4px;
            margin-bottom: 12px;
        }
        .candidate-view-divider{
            margin-top: 8px;
            margin-bottom: 8px;
            border: 2px solid var(--primary-red);
        }
        .candidate-view-item-grid{
            margin-top: 24px;
            margin-left: auto;
            margin-right: auto;
            display: grid;
            width: 100%;
            grid-template-columns: 50% 50%;

            @include media-breakpoint-down(sm){
                grid-template-columns: 100%;
            }
           
        }

        .candidate-view-profile-img{
            width: 150px;
            height: 150px;
            border-radius: 50%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 24px;
        }
        .candidate-view-icon{
            width: 24px;
            font-size: 16px;
            color: $text-color;
            margin-top: auto;
            margin-bottom: auto;
            @include media-breakpoint-down(sm){
                font-size: 12px;
                width: 14px;
            }
        }

        .candidate-view-title{
            margin-left: 14px;
            font-size: 14px;
            color: $text-gray-color;
            font-family: $fontRegular;
            margin-top: auto;
            margin-bottom: auto;

            @include media-breakpoint-down(sm){
                font-size: 12px;
            }
        }

        .candidate-view-title-id{
            margin-left: 4px;
            font-size: 14px;
            color: $text-gray-color;
            font-family: $fontRegular;
            margin-top: auto;
            margin-bottom: auto;

            @include media-breakpoint-down(sm){
                font-size: 12px;
            }
        }
        .candidate-view-title-black{
            margin-left: 14px;
            font-size: 14px;
            color: $text-color;
            font-family: $fontMedium;
            margin-top: auto;
            margin-bottom: auto;

            @include media-breakpoint-down(sm){
                font-size: 12px;
            }
        }
        .candidate-view-value{
            margin-left: 4px;
            font-size: 14px;
            color: $text-color;
            font-family: $fontMedium;
            margin-top: auto;
            margin-bottom: auto;
            @include media-breakpoint-down(sm){
                font-size: 12px;
            }            
        }
        .candidate-view-value-id{
            font-size: 20px;
            color: $text-color;
            font-family: $fontMedium;
            margin-top: auto;
            margin-bottom: auto;
            @include media-breakpoint-down(sm){
                font-size: 16px;
            }            
        }
        .candidate-view-history-control{
            margin-top: 16px;
        }

        .candidate-view-resume-button{
            width: 200px;
            height: 41px;
            color: $text-white-color;
            background-color: var(--primary-red);
            border-radius: 8px;
            border: none;
            margin-bottom: 8px;
            margin-left: auto;
            margin-right: auto;
            font-size: 14px;

            @include media-breakpoint-down(sm){
                height: 35px;
                font-size: 14px;
            }
        }
        .box-candidate-skills-tag-control{
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            margin-bottom: 16px;
        }
        .box-candidate-skills-tag{
            margin-top: 2px;
            margin-bottom: 2px;
            justify-content: center;
            border-radius: 14px;
            min-height: 31px;
            color: $text-color;
            font-family: $fontRegular;
            border: 1px solid $border-input;
            margin-right: 3px;
            width: auto;
            padding: 6px 14px;
            font-size: 13px;
            @include media-breakpoint-down(sm){
                font-size: 11px;
                min-height: 22px;
                padding: 3px 12px;
            }
        }
        .box-candidate-skills-tag-control-highlight{
            display: flex;
            width: 100%;
            flex-wrap: wrap;
        }
        .box-candidate-skills-tag-highlight{
            margin-top: 2px;
            margin-bottom: 2px;
            justify-content: center;
            border-radius: 14px;
            min-height: 31px;
            background-color: var(--primary-blue);
            color: $text-white-color;
            font-family: $fontRegular;
            border: 1px solid $border-input;
            margin-right: 3px;
            width: auto;
            padding: 6px 14px;
            font-size: 13px;
            @include media-breakpoint-down(sm){
                font-size: 11px;
                min-height: 22px;
                padding: 3px 12px;
            }
        }
    }
}