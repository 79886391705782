@import "../../../App.scss";


.modal-img-profile {
    width: 100%;
    height: auto;
    object-fit: contain;
}
.bookmark-loading-control{
    height: 80vh;
    display: flex;
    flex-direction: column;

    .bookmark-notfound-control{
        display: flex;
        margin: auto;
        flex-direction: column;

        .bookmark-notfound-text{
            margin-left: auto;
            margin-right: auto;
            font-size: 14px;
            color: $text-gray-color;
            font-family: $fontRegular;
        }
    }
}
.result-search-main {
    width: 98%;
    min-height: 95vh;
    display: flex;

    .result-hightlight-keyword{
        background-color: rgb(253, 203, 20) !important;
    }

    .no-resumes-control {
        height: 100%;
        display: flex;
        margin: auto;

        .no-resumes-item-control {
            margin: auto;
            display: flex;
            flex-direction: column;

            .no-resumes-icon {
                font-size: 48px;
                color: var(--primary-red);
                margin-left: auto;
                margin-right: auto;
            }

            .no-resumes-text {
                font-size: 18px;
                color: var(--text-color);
                margin-left: auto;
                margin-right: auto;
                margin-top: 16px;
                font-family: $fontMedium;
            }

            .no-resumes-button {
                width: 100%;
                height: 41px;
                background-color: var(--primary-red);
                border-radius: 8px;
                border: none;
                color: $text-white-color;
                font-family: $fontMedium;
                font-size: 15px;
                margin-top: 10%;
            }
        }
    }

    .text-result-header-control {
        cursor: pointer;
        display: flex;
        width: 100%;

        .text-result-header-icon {
            margin-left: auto;
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    .scroll-top-div {
        cursor: pointer;
        width: 40px;
        height: 40px;
        background-color: var(--primary-blue);
        border-radius: 6px;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        margin-right: 16px;
        margin-bottom: 16px;
        margin-left: auto;
        z-index: 999;

        @include media-breakpoint-down(sm) {
            width: 30px;
            height: 30px;
        }

        .scroll-img {
            width: 30px;
            height: auto;
            object-fit: contain;

            @include media-breakpoint-down(sm) {
                width: 20px;
            }
        }
    }

    .text-result-header {
        font-size: 14px;
        color: white;
        margin-top: auto;
        margin-bottom: auto;
        font-family: $fontMedium;
        width: 100%;

        @include media-breakpoint-down(lg) {
            font-size: 14px;
        }

        @include media-breakpoint-down(md) {
            font-size: 14px;
            width: 100%;
        }

        @include media-breakpoint-down(sm) {
            font-size: 11px;
            width: 100%;
        }
    }

    

    .loadmore-candidate-control {
        display: flex;
        height: 100%;
        width: 100%;

        .loadmore-candidate-main {
            margin: auto;
            display: flex;
            flex-direction: column;

            .loadmore-progress {
                width: 40px;
                height: 40px;
                margin-left: auto;
                margin-right: auto;
            }

            .loadmore-text {
                font-size: 14px;
                color: $text-color;
                font-family: $fontRegular;
                margin-top: 16px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .button-export-to {
        border-radius: 8px;
        margin-right: 14px;
        border: 2px solid white;
        min-width: 100px;
        display: flex;
        padding-left: 14px;
        padding-right: 14px;
        height: 49px;

        @include media-breakpoint-down(md) {
            height: 39px;
            margin-right: 0px;
        }

        .button-export-text-control {
            margin: auto;
            display: flex;
        }

        .button-exprot-hr9-logo {
            width: auto;
            height: 20px;
            object-fit: contain;
            margin-left: 6px;
        }

        .button-export-text {
            font-size: 14px;
            color: white;
            font-family: $fontMedium;
        }
    }

    .result-resume-responsibilt-link {
        cursor: pointer;
        color: var(--button-primary-color);
        font-size: 12px;
        font-family: $fontMedium;
        text-decoration: underline;
      
    }

    .result-resume-responsibilt-text {
        font-size: 12px;
        font-family: $fontRegular;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;

    }

    .button-export-to-active {
        border-radius: 8px;
        margin-right: 14px;
        border: 2px solid var(--primary-red);
        min-width: 100px;
        display: flex;
        padding-left: 14px;
        padding-right: 14px;
        height: 49px;
        background-color: var(--primary-red);

        @include media-breakpoint-down(md) {
            height: 39px;
            margin-right: 0px;
        }

        .button-export-text-control {
            margin: auto;
            display: flex;
        }

        .button-exprot-hr9-logo {
            width: auto;
            height: 20px;
            object-fit: contain;
            margin-left: 6px;
        }

        .button-export-text {
            font-size: 14px;
            color: white;
            font-family: $fontMedium;
        }
    }


    .button-load-candidate-more {
        width: 70%;
        height: 41px;
        border-radius: 8px;
        font-size: 14px;
        color: white;
        font-family: $fontMedium;
        margin-left: auto;
        margin-right: auto;
        margin-top: 12px;
        margin-bottom: 12px;
        border: none;
        background-color: var(--primary-red);
    }

    .open-candidate-resume-control {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;

        @media screen and (max-width: 1200px) {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
        }

        @include media-breakpoint-down(md) {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
        }

        .open-candidate-resume-button-active {
            width: 100%;
            background-color: white;
            border-radius: 8px;
            border: 1px solid var(--primary-red);
            margin-top: 16px;
            font-size: 13px;
            font-family: $fontMedium;
            color: var(--primary-red);
            padding: 6px 14px;
            line-height: 1.2em;
            display: flex;
            height: 41px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            font-size: 13px;


            @media screen and (max-width: 1200px) {
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                font-size: 13px;
                height: 41px;

            }

            @include media-breakpoint-down(md) {
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                font-size: 13px;
                height: 31px;
            }

            .open-candidate-button-200-credit {
                font-size: 14px;
                margin-left: 5px;
                font-family: $fontRegular;
                color: var(--primary-red);
            }

        }

        .open-candidate-resume-button {
            width: 100%;
            background-color: var(--primary-red);
            border-radius: 8px;
            border: none;
            margin-top: 16px;
            font-size: 13px;
            font-family: $fontMedium;
            color: white;
            padding: 6px 14px;
            line-height: 1.2em;
            display: flex;
            height: 41px;

            @media screen and (max-width: 1200px) {
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                font-size: 13px;
                height: 41px;

            }

            @include media-breakpoint-down(md) {
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                font-size: 13px;
                height: 31px;
            }

            .open-candidate-button-200-credit {
                font-size: 14px;
                margin-left: 5px;
                font-family: $fontRegular;
                color: $text-white-color;
            }
        }

        .open-candidate-resume-button-icon {
            margin-right: 12px;
        }
    }

    .search-note-control {
        display: flex;
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 7px;
        border-right: 1px solid white;
        padding-right: 8px;

        @include media-breakpoint-down(md) {
            margin-left: auto;
            margin-top: 6px;
        }

        .search-note-icon {
            font-size: 16px;
            margin-right: 2px;
            color: $text-white-color;
            margin-top: auto;
            margin-bottom: auto;
        }

        .search-note-text {
            font-size: 16px;
            font-family: $fontMedium;
            color: $text-white-color;
            margin-left: 3px;

            @include media-breakpoint-down(lg) {
                font-size: 13px;
            }
        }
    }

    .box-candidate-top-action-control{
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        background-color: var(--primary-red);
    }

    .result-box-candidate-control {
        overflow-y: scroll;
        margin-left: auto;
        margin-bottom: 6px;
        width: 100%;
        height: 100%;
        scroll-behavior: smooth;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        position: relative;
        padding-left: 10px;
        padding-right: 10px;
        direction: ltr;

        @include media-breakpoint-down(lg) {
            height: auto;
            margin-top: 0px;
            width: 100%;
            padding: 4px;
        }

        @include media-breakpoint-down(md) {
            margin-top: 0px;
            width: 100%;
            margin-bottom: 0px;
        }

        .box-candidate-header-control {
            margin-top: 4px;
            padding: 12px 12px;
            width: auto;
            position: sticky;
            top: 0;
            z-index: 9;
            display: grid;
            grid-template-columns: 40% 60%;
            background-color: var(--primary-blue);
            border-radius: 12px;

            @include media-breakpoint-down(lg) {
                grid-template-columns: 30% 70%;
            }

            @include media-breakpoint-down(md) {
                grid-template-columns: 30% 70%;
            }

            @include media-breakpoint-down(sm) {
                grid-template-columns: 40% 60%;

            }
        }

        .filter-control {
            background-color: transparent !important;
            margin-left: auto;
            display: flex;
            cursor: pointer;

            @include media-breakpoint-down(md) {
                flex-direction: column;
            }

            .import-connect {
                width: auto;
                height: 41px;
                object-fit: contain;
                margin-top: auto;
                margin-bottom: auto;
                cursor: pointer;
                margin-right: 24px;

                @include media-breakpoint-down(md) {
                    margin-left: auto;
                    height: 31px;
                    margin-right: 0px;
                }
            }

            .import-connect-text-red {
                font-size: 13px;
                font-family: $fontMedium;
                text-decoration: underline;
                color: var(--primary-red);
                margin-top: 16px;
                margin-left: auto;
            }

            .filter-dropdown {
                background-color: transparent;
                border-radius: 8px;
                border: none;
                padding: 4px 6px;
            }

            .filter-img {
                font-size: 16px;
                color: white;
                margin-top: auto;
                margin-bottom: auto;

                @include media-breakpoint-down(sm) {
                    width: 18px;
                    height: 18px;
                }
            }

            .filter-text {
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 4px;
                font-size: 16px;
                color: white;
                font-family: $fontRegular;

                @include media-breakpoint-down(lg) {
                    font-size: 13px;
                }
            }

            .filter-icon {
                margin-top: auto;
                margin-bottom: auto;
                font-size: 16px;
                margin-left: 14px;
                color: white;
            }

        }

        .box-candidate-control-selected {
            box-shadow: 0px 0px 14px 0px var(--primary-red);
            animation: blink-border 2s infinite;
        }

        .box-candidate-grid-control {
            display: grid;
            grid-template-columns: 50% 50%;

            @include media-breakpoint-down(lg) {
                grid-template-columns: 100%;
                margin-top: 36px;
            }

            @include media-breakpoint-down(md) {
                margin-top: 12px;
            }
        }

        .box-candidate-control {
            background: white;
            box-shadow: 0px 2px 6px #888;
            border-radius: 12px;
            margin-top: 12px;
            margin-bottom: 12px;
            width: 97%;
            display: flex;
            margin-left: auto;
            margin-right: auto;
            flex-direction: column;

            @include media-breakpoint-down(lg) {
                margin-top: 0px;
            }

            .box-candidate-content-control {
                padding: 15px 18px;
                display: flex;
                height: 100%;
                flex-direction: column;

                @include media-breakpoint-down(lg) {
                    padding: 12px;
                }

                .box-candidate-import-select-control {
                    display: flex;
                    margin-bottom: 14px;

                    .box-candidate-import-select {
                        margin-right: 6px;
                    }

                    .box-candidate-import-text {
                        font-size: 14px;
                        font-family: $fontMedium;
                        color: var(--button-primary-color);
                        margin-top: auto;
                        margin-bottom: auto;
                    }
                }


                .box-candidate-head-control {
                    display: flex;
                    position: relative;
                    min-height: 110px;

                    @include media-breakpoint-down(md) {
                        flex-direction: column;
                        min-height: auto;
                    }

                    @include media-breakpoint-down(sm) {
                        flex-direction: column;
                        min-height: auto;
                    }

                    .box-candidate-new-tab-icon {
                        cursor: pointer;
                        position: absolute;
                        right: 0;
                        margin-left: auto;
                        font-size: 18px;
                        color: var(--primary-red);
                    }

                    .box-candidate-last-update-control-and-logs {
                        margin-left: auto;
                        width: 50%;

                        @include media-breakpoint-down(md) {
                            margin-right: auto;
                            margin-left: 0;
                        }
                    }

                    .box-candidate-last-update-control {
                        margin-left: auto;
                        text-align: right;


                        @include media-breakpoint-down(md) {
                            margin-right: auto;
                            margin-left: 0;
                        }
                    }

                    .box-candidate-bottom-icon-gray {
                        width: 18px;
                        height: 18px;
                        object-fit: contain;
                        color: #888;
                        margin-right: 6px;
                        margin-top: auto;
                        margin-bottom: auto;

                        @include media-breakpoint-down(sm) {
                            width: 12px;
                            height: 12px;
                        }
                    }

                    .box-candidate-bottom-font-gray {
                        margin-top: auto;
                        margin-bottom: auto;
                        font-size: 14px;
                        color: #888;

                        @include media-breakpoint-down(md) {
                            font-size: 14px;
                        }

                        @include media-breakpoint-down(sm) {
                            font-size: 12px;
                        }
                    }

                    .box-candidate-bottom-font-blue {
                        cursor: pointer;
                        margin-top: auto;
                        margin-bottom: auto;
                        font-size: 14px;
                        color: var(--button-primary-color);

                        @include media-breakpoint-down(md) {
                            font-size: 14px;
                        }

                        @include media-breakpoint-down(sm) {
                            font-size: 12px;
                        }

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                .box-candidate-job-title-control {
                    display: flex;
                    margin-bottom: 4px;

                    .box-candidate-job-profile-img {
                        width: 90px;
                        height: 90px;
                        cursor: pointer;
                        object-fit: contain;
                        border-radius: 50%;
                        margin-bottom: 8px;
                    }

                    .box-candidate-job-title-icon {
                        width: 15px;
                        height: 15px;
                        object-fit: contain;
                        color: #9B9B9B;
                        margin-right: 6px;
                        margin-top: auto;
                        margin-bottom: auto;

                        @include media-breakpoint-down(sm) {
                            width: 12px;
                            height: 12px;
                        }
                    }

                    .box-candidate-job-title-null {
                        font-size: 14px;
                        color: $text-gray-color;
                        margin-left: 6px;
                        font-family: $fontRegular;

                        @include media-breakpoint-down(md) {
                            font-size: 14px;
                        }

                        @include media-breakpoint-down(sm) {
                            font-size: 12px;
                        }
                    }

                    .box-candidate-job-title {
                        font-size: 14px;
                        color: var(--text-color);
                        margin-left: 6px;
                        font-family: $fontMedium;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 350px;

                        @media screen and (max-width: 1200px) {
                            width: 250px;
                        }

                        @include media-breakpoint-down(md) {
                            font-size: 14px;
                        }

                        @include media-breakpoint-down(sm) {
                            font-size: 14px;
                            width: 250px;
                        }
                    }

                    .box-candidate-job-exp {
                        font-size: 14px;
                        color: var(--text-color);
                        margin-left: 6px;
                        font-family: $fontMedium;
                        word-wrap: break-word;

                        @media screen and (max-width: 1200px) {
                            font-size: 13px;
                        }

                        @include media-breakpoint-down(md) {
                            font-size: 14px;
                        }

                        @include media-breakpoint-down(sm) {
                            font-size: 12px;
                        }
                    }

                    .box-candidate-job-company-control {
                        display: flex;
                        flex-wrap: nowrap;
                        position: relative;
                        width: 100%;
                    }

                    .box-candidate-job-company {
                        font-size: 14px;
                        color: #888;
                        margin-left: 6px;
                        margin-top: auto;
                        margin-bottom: auto;
                        font-family: $fontRegular;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        @include media-breakpoint-down(md) {
                            font-size: 14px;
                        }

                        @include media-breakpoint-down(sm) {
                            font-size: 12px;
                        }
                    }

                    .box-candidate-job-company-bold {
                        font-size: 14px;
                        color: #888;
                        margin-left: 6px;
                        margin-top: auto;
                        margin-bottom: auto;
                        font-family: $fontMedium;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        @include media-breakpoint-down(md) {
                            font-size: 14px;
                        }

                        @include media-breakpoint-down(sm) {
                            font-size: 12px;
                        }
                    }

                    .box-candidate-job-company-big {
                        font-size: 15px;
                        color: var(--text-color);
                        margin-top: auto;
                        margin-bottom: auto;
                        margin-left: 6px;
                        overflow: hidden;
                        font-family: $fontMedium;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        width: 280px;
                        @media screen and (min-width: 1500px) {
                            font-size: 13px;
                            width: 350px;
                        }
                        @media screen and (max-width: 1200px) {
                            font-size: 13px;
                            width: 250px;
                        }

                        @include media-breakpoint-down(md) {
                            font-size: 14px;
                            width: 300px;
                        }

                        @include media-breakpoint-down(sm) {
                            font-size: 12px;
                            width: 200px;
                        }
                    }

                    .box-candidate-job-ID {
                        cursor: pointer;
                        font-size: 15px;
                        color: var(--button-primary-color);
                        margin-top: auto;
                        margin-bottom: auto;
                        margin-left: 6px;
                        font-family: $fontMedium;
                        word-wrap: break-word;

                        @media screen and (max-width: 1200px) {
                            font-size: 13px;
                        }

                        @include media-breakpoint-down(md) {
                            font-size: 14px;
                        }

                        @include media-breakpoint-down(sm) {
                            font-size: 12px;
                        }

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    .box-candidate-job-company-copy {
                        cursor: pointer;
                        margin-left: 6px;
                        color: var(--button-primary-color);
                        font-size: 15px;

                        @include media-breakpoint-down(sm) {
                            font-size: 12px;
                        }
                    }
                }

                .box-candidate-experience-text {
                    font-family: $fontRegular;
                    font-size: 14px;
                    color: #888;

                    @media screen and (max-width: 1200px) {
                        font-size: 13px;
                    }

                    @include media-breakpoint-down(md) {
                        font-size: 14px;
                    }

                    @include media-breakpoint-down(sm) {
                        font-size: 12px;
                    }
                }

                .candidate-bottom-control {
                    display: flex;
                    margin-top: auto;
                    flex-direction: column;

                    @media screen and (max-width: 1200px) {
                        flex-direction: column;
                    }

                    @include media-breakpoint-down(lg) {
                        flex-direction: column;
                    }

                    .candidate-bottom-history-control {
                        display: flex;
                        margin-top: auto;
                        margin-top: 16px;
                        width: 100%;

                        @media screen and (max-width: 1200px) {
                            width: 100%;
                        }

                        @include media-breakpoint-down(md) {
                            width: 100%;
                        }


                    }

                    .candidate-bottom-icon {
                        font-size: 15px;
                        color: $text-gray-color;
                        font-family: $fontRegular;
                        margin-top: auto;
                        margin-bottom: auto;

                        @include media-breakpoint-down(md) {
                            font-size: 12px;
                        }
                    }

                    .candidate-bottom-history {
                        font-size: 13px;
                        width: 100%;
                        margin-left: 14px;
                        color: $text-gray-color;
                        font-family: $fontRegular;
                        margin-top: auto;
                        margin-bottom: auto;

                        @media screen and (max-width: 1200px) {
                            width: 100%;
                        }

                        @include media-breakpoint-down(md) {
                            font-size: 12px;
                        }
                    }

                }

                .box-candidate-experience-link {
                    cursor: pointer;
                    font-family: $fontMedium;
                    font-size: 13px;
                    margin-left: 6px;
                    text-decoration: underline;
                    color: var(--button-primary-color);

                    @include media-breakpoint-down(md) {
                        font-size: 14px;
                    }

                    @include media-breakpoint-down(sm) {
                        font-size: 12px;
                    }
                }

                .box-candidate-activity-link {
                    cursor: pointer;
                    font-family: $fontMedium;
                    font-size: 13px;
                    text-decoration: underline;
                    color: var(--button-primary-color);

                    @include media-breakpoint-down(md) {
                        font-size: 13px;

                    }

                    @include media-breakpoint-down(sm) {
                        font-size: 12px;
                        margin-left: 2px;
                    }
                }

                .box-resume-control {
                    cursor: pointer;
                    display: flex;
                    background: white;
                    padding: 9px 12px;
                    width: 50%;
                    margin-left: auto;
                    margin-right: 0;
                    box-shadow: 0px 0px 4px rgba(51, 51, 51, 0.238117);
                    border-radius: 8px;
                    margin-top: auto;
                    margin-bottom: auto;

                    @include media-breakpoint-down(lg) {
                        width: 100%;
                    }

                    @include media-breakpoint-down(md) {
                        margin-top: 18px;
                    }

                    .box-resume-img {
                        width: 16px;
                        height: auto;
                        object-fit: contain;
                        margin-right: 4px;
                    }

                    .box-resume-content-control {
                        display: flex;
                        flex-direction: column;
                        margin-top: auto;
                        padding-left: 7px;
                        margin-bottom: auto;

                        .box-resume-content-title {
                            color: var(--button-primary-color);
                            font-size: 13px;
                            font-family: $fontRegular;
                        }

                        .box-resume-content-date {
                            color: #9B9B9B;
                            font-size: 12px;
                            font-family: $fontRegular;
                        }
                    }
                }

                .box-candidate-skills-no-result {
                    min-height: 100px;
                    display: flex;

                    .box-candidate-skills-no-result-control {
                        margin: auto;
                        display: flex;
                        flex-direction: column;

                        .box-candidate-skills-no-result-text {
                            font-size: 14px;
                            font-family: $fontRegular;
                            color: var(--text-color);
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }
                }

                .box-candidate-skills-tag-control {
                    display: flex;
                    width: 100%;
                    flex-wrap: wrap;

                }

                .box-candidate-skills-tag-item-control-highlight {
                    display: flex;
                    margin-top: 2px;
                    margin-bottom: 2px;
                    border: none;
                    background-color: var(--primary-blue);
                    margin-right: 3px;
                    border-radius: 14px;
                    min-height: 26px;
                    justify-content: center;
                    padding-left: 12px;
                    padding-right: 12px;
                    padding-top: 4px;
                    padding-bottom: 4px;
                    line-height: 1em;

                    @include media-breakpoint-down(md) {
                        min-height: 20px;
                        padding-top: 2px;
                        padding-bottom: 2px;
                    }

                }

                .box-candidate-skills-tag-highlight {
                    color: $text-white-color;
                    font-family: $fontRegular;
                    width: auto;
                    margin-top: auto;
                    font-size: 11px;
                    margin-bottom: auto;

                    @include media-breakpoint-down(md) {
                        font-size: 9px;
                        min-height: 16px;
                        padding: 3px 4px;
                    }
                }

                .box-candidate-skills-tag-item-control {
                    display: flex;
                    margin-top: 2px;
                    margin-bottom: 2px;
                    border: 1px solid $border-input;
                    margin-right: 3px;
                    border-radius: 14px;
                    min-height: 26px;
                    justify-content: center;
                    padding-left: 12px;
                    padding-right: 12px;
                    padding-top: 4px;
                    padding-bottom: 4px;
                    line-height: 1em;

                    @include media-breakpoint-down(md) {
                        min-height: 20px;
                        padding-top: 2px;
                        padding-bottom: 2px;
                    }
                }

                .box-candidate-skills-tag {
                    color: $text-color;

                    font-family: $fontRegular;
                    width: auto;
                    margin-top: auto;
                    font-size: 11px;
                    margin-bottom: auto;

                    @include media-breakpoint-down(md) {
                        font-size: 9px;
                        min-height: 16px;
                        padding: 3px 4px;
                    }
                }



                .box-candidate-info-control {
                    margin-top: 16px;
                    display: grid;
                    grid-template-columns: 25% 75%;

                    @include media-breakpoint-down(lg) {
                        grid-template-columns: 25% 75%;
                    }

                    @include media-breakpoint-down(md) {
                        grid-template-columns: 50% 50%;
                    }

                    @include media-breakpoint-down(sm) {
                        grid-template-columns: 100%;
                        justify-content: center;
                    }

                    .box-candidate-info-img {
                        width: 62px;
                        height: 62px;
                        border-radius: 50%;
                        object-fit: contain;
                        margin-top: auto;
                        margin-bottom: auto;

                        @include media-breakpoint-down(lg) {
                            margin-left: auto;
                            margin-right: auto;
                            width: 90px;
                            height: auto;
                        }

                        @include media-breakpoint-down(md) {
                            object-fit: cover;
                        }
                    }

                    .box-candidate-info-text-name-link {
                        font-size: 17px;
                        font-family: $fontMedium;
                        color: var(--button-primary-color);

                        @include media-breakpoint-down(lg) {
                            margin-left: 16px;
                        }

                        @include media-breakpoint-down(md) {
                            margin-left: 0px;
                            margin-top: 6px;
                            font-size: 14px;
                        }

                        @include media-breakpoint-down(sm) {
                            margin-left: auto;
                            margin-right: auto;
                            margin-top: 16px;
                            font-size: 14px;
                        }
                    }

                    .box-candidate-info-text-name {
                        font-size: 17px;
                        font-family: $fontMedium;
                        color: var(--text-color);

                        @include media-breakpoint-down(lg) {
                            margin-left: 16px;
                        }

                        @include media-breakpoint-down(md) {
                            margin-left: 0px;
                            margin-top: 6px;
                            font-size: 14px;
                        }

                        @include media-breakpoint-down(sm) {
                            margin-left: auto;
                            margin-right: auto;
                            margin-top: 16px;
                            font-size: 14px;
                        }
                    }

                    .box-candidate-info-text-control {
                        margin-left: 4px;
                        margin-right: 4px;
                        margin-top: auto;
                        display: grid;
                        grid-template-columns: 50% 50%;

                        @include media-breakpoint-down(lg) {
                            grid-template-columns: 100%;
                        }

                        @include media-breakpoint-down(md) {
                            margin-top: 24px;
                            margin-bottom: 24px;
                        }

                        .box-candidate-item {
                            display: flex;
                            flex-direction: column;
                            overflow-wrap: break-word;

                            @include media-breakpoint-down(lg) {
                                margin-left: 16px;
                            }

                            @include media-breakpoint-down(sm) {
                                width: 100%;
                                margin-left: auto;
                                margin-right: auto;
                                text-align: left;

                            }
                        }


                        .box-candidate-info-sign {
                            font-size: 14px;
                            color: var(--text-color);
                            font-weight: 300;
                            font-family: $fontLight;
                            margin-right: 8px;
                        }

                        .box-candidate-info-text-desc {
                            font-size: 14px;
                            font-family: $fontRegular;
                            color: var(--text-color);
                            display: flex;
                            word-break: break-all;
                            margin-top: auto;
                            margin-bottom: auto;

                            @include media-breakpoint-down(md) {}
                        }
                    }
                }
            }

            .box-candidate-bottom-control {
                margin-top: auto;
                width: 100%;
                min-height: 50px;
                background-color: #F8F8F8;
                border-bottom-left-radius: 12px;
                padding: 12px 24px;
                border-bottom-right-radius: 12px;
                display: flex;
                flex-direction: column;

                @include media-breakpoint-down(sm) {
                    padding: 12px 12px;
                }

                .box-note-text-area {
                    margin-top: 8px;
                    width: 100%;
                    border: none;
                    font-size: 12px;
                    font-family: $fontRegular;
                    color: var(--text-color);
                }

                .box-note-title-control {
                    width: 100%;
                    display: flex;
                    position: relative;

                }

                .box-note-title {
                    width: 90%;
                    font-size: 12px;
                    color: #828282;
                    font-family: $fontRegular;

                    @include media-breakpoint-down(md) {
                        width: 100%;
                        margin-top: 8px;
                    }

                }

                .box-note-title-author {
                    margin-top: 6px;
                    font-size: 12px;
                    color: #828282;
                    font-family: $fontMedium;
                }

                .box-candidate-bottom-edit {
                    position: absolute;
                    margin-left: auto;
                    margin-bottom: auto;
                    margin-top: 0px;
                    width: 300px;
                    right: 0;
                    text-align: right;
                }

                .box-note-show-text-control {
                    display: flex;
                    flex-direction: row;

                    @include media-breakpoint-down(md) {
                        flex-direction: column;
                    }
                }

                .box-note-expand-text {
                    cursor: pointer;
                    color: var(--button-primary-color);
                    text-decoration: underline;
                    margin-left: 4px;
                    word-break: break-all;
                }

                .box-candidate-bottom-icon {
                    width: 15px;
                    height: 15px;
                    object-fit: contain;
                    color: #4A90E2;
                    margin-right: 6px;
                    margin-top: auto;
                    margin-bottom: auto;

                    @include media-breakpoint-down(sm) {
                        width: 15px;
                        height: 15px;

                    }
                }

                .box-candidate-bottom-font {
                    margin-top: auto;
                    margin-bottom: auto;
                    font-size: 14px;
                    font-family: $fontMedium;
                    color: #4A90E2;
                    cursor: pointer;

                    @include media-breakpoint-down(md) {
                        font-size: 14px;
                    }

                    @include media-breakpoint-down(sm) {
                        font-size: 12px;
                    }
                }

                .box-note-add-note {
                    cursor: pointer;
                    color: var(--button-primary-color);
                    font-size: 14px;
                    font-family: $fontRegular;
                    margin-top: auto;
                    margin-bottom: auto;


                    .add-note-text {
                        margin-left: 4px;
                    }

                    &:hover {
                        text-decoration: underline;
                        font-family: $fontMedium;
                    }
                }

                .box-note-cancel-note {
                    cursor: pointer;
                    color: var(--primary-red);
                    font-size: 14px;
                    font-family: $fontRegular;
                    margin-top: auto;
                    margin-bottom: auto;
                    margin-left: 24px;

                    .add-cancel-text {
                        margin-left: 4px;

                    }


                    &:hover {
                        text-decoration: underline;
                        font-family: $fontMedium;
                    }
                }



            }
        }

        .match-skill-candidate-control {

            display: flex;

            @include media-breakpoint-down(md) {
                flex-wrap: wrap;
            }

            .skill-candidate-star {
                width: 20px;
                height: 20px;
                object-fit: contain;
                margin-right: 8px;
                margin-top: auto;
                margin-bottom: auto;
            }

            .skill-candidate-text-title {
                margin-top: auto;
                margin-bottom: auto;
                font-size: 16px;
                font-family: $fontMedium;
                color: var(--text-color);

                @include media-breakpoint-down(md) {
                    font-size: 14px;
                }
            }

            .skill-candidate-text-link {
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 4px;
                font-size: 16px;
                font-family: $fontMedium;
                color: var(--button-primary-color);
            }
        }

        .skill-tag-candidate-main{
            display: flex;
            flex-direction: column;
            margin-bottom: 8px;
        }

        .skill-tag-candidate-control {
            display: flex;
        }

        .skill-candidate-text-regular-icon {
            font-size: 13px;
            color: #888;
            margin-right: 6px;
            margin-top: auto;
            margin-bottom: auto;

            @include media-breakpoint-down(sm) {
                font-size: 12px;
            }
        }

        .skill-candidate-text-regular {
            margin-top: 6px;
            font-size: 14px;
            margin-top: auto;
            margin-block-start: auto;
            font-family: $fontRegular;
            color: #888;

            @include media-breakpoint-down(sm) {
                font-size: 12px;
            }
        }

        .skill-candidate-text-bold {
            font-size: 15px;
            font-family: $fontMedium;
            color: var(--text-color);

            @include media-breakpoint-down(sm) {
                font-size: 13px;
            }
        }

        .line-box-divier {
            width: 100%;
            margin-top: 12px;
            margin-bottom: 0px;
            height: 2px;
            background-color: #CACACA;

            @include media-breakpoint-down(sm) {
                margin-top: 4px;
                margin-bottom: 0px;
            }
        }

        .line-box-content-divier {
            width: 100%;
            margin-top: 12px;
            margin-bottom: 12px;
            height: 2px;
            background-color: #CACACA;

            @include media-breakpoint-down(sm) {
                margin-top: 4px;
                margin-bottom: 4px;
            }

        }

    }

    @keyframes blink-border {

        0%,
        100% {
            box-shadow: 0px 0px 14px 0px var(--primary-red);
            /* Shadow with your preferred color and size */
        }

        50% {
            box-shadow: 0px 2px 6px #888;
            /* Shadow disappears */
        }
    }
}