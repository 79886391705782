@import "../App.scss";

.candidate-import-modal-main{

    
    .modal-dialog {
        max-width: 40% !important;
        width: 40% !important;
        height: 95vh !important;
        margin-bottom: 5% !important;
        padding-bottom: 5%;
        @include media-breakpoint-down(xl){
            max-width: 60% !important;
            width: 60% !important;
        }
        @include media-breakpoint-down(md){
            width: 100% !important;
            max-width: 100% !important;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .modal-content{
        height: 95vh !important;
        overflow-y: scroll;
    }
  
    .candidate-import-item-control{
        display: flex;
        flex-direction: column;
        .candidate-import-success-control{
            margin: auto;
            display: flex;
            height: 50vh;
            flex-direction: column;
            .candidate-import-success-control-main{
                margin: auto;
                display: flex;
                flex-direction: column;
                .candidate-import-success-progress{
                  font-size: 48px;
                  color: green;
                    margin-left: auto;
                    margin-right: auto;
                }

                .candidate-import-success-progress-text{
                    font-size: 16px;
                    margin-top: 24px;
                    font-family: $fontRegular;
                    margin-left: auto;
                    margin-right: auto;
                }

                .candidate-import-success-progress-close{
                    font-size: 14px;
                    margin-top: 24px;
                    cursor: pointer;
                    text-decoration: underline;
                    font-family: $fontRegular;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
           
        }
        .candidate-import-loading-control{
            margin: auto;
            display: flex;
            height: 50vh;
            flex-direction: column;
            .candidate-import-loading-control-main{
                margin: auto;
                display: flex;
                flex-direction: column;
                .candidate-import-loading-progress{
                    width: 30px;
                    height: 30px;
                    object-fit: contain;
                    margin-left: auto;
                    margin-right: auto;
                }

                .candidate-import-loading-progress-text{
                    font-size: 16px;
                    margin-top: 24px;
                    font-family: $fontRegular;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
           
        }

        .input-search-error-text{
            font-size: 14px;
            color: $red-color;
            font-family: $fontRegular;
        }

        .candidate-import-title{
            font-size: 17px;
            font-family: $fontMedium;
            color: $text-color;
            @include media-breakpoint-down(sm){
                font-size: 14px;
            }
           
        }
        .candidate-import-info-control{
            display: grid;
            grid-template-columns: 10% 70% 20%;
            margin-top: 6px;
            margin-bottom: 6px;

            @include media-breakpoint-down(sm){
                grid-template-columns: 10% 60% 30%;
            }

            .candidate-import-info-text{
                font-size: 14px;
                font-family: $fontMedium;
                color: $text-color;
                margin-top: auto;
                margin-bottom: auto;
                margin-left: auto;
                margin-right: auto;
                @include media-breakpoint-down(sm){
                    font-size: 11px;
                }
            }

            .candidate-import-button{
                border: none;
                font-size: 14px;
                font-family: $fontMedium;
                background-color: $primary-color;

                @include media-breakpoint-down(sm){
                    font-size: 9px;
                    width: 100%;
                }
            }

           
        }
        .candidate-import-divider{
            margin-top: 4px;
            margin-bottom: 4px;
            border: 1px solid #7b7b7b;
        }

        .candidate-import-id-title{
            font-size: 15px;
            margin-top: 30px;
            color: $text-color;
            font-family: $fontMedium;
            @include media-breakpoint-down(sm){
                font-size: 12px;
            }
        }

        .candidate-import-id-input{
           margin-top: 12px;
        }

        .candidate-import-submit{
            margin-top: 16px;
            background-color: $primary-color;
            width: 100%;
            height: 41px;
            border:none;
            color: $text-white-color;
            font-size: 16px;

        
        }
    }

}