@import "../App.scss";


.activity-logs-control{
    border-radius: 24px;
    .activity-logs-title{
        font-size: 15px;
        font-family: $fontMedium;
        color: black;
    }
    .activity-logs-list-control{
        display: flex;
        flex-direction: column;

        .activity-section-control{
            padding-bottom: 4px;
            border-bottom: 2px solid #888;
            margin-bottom: 14px;
        }
        .activity-logs-list-date{
            font-size: 15px;
            font-family: $fontMedium;
            color: black;
            
        }
        .activity-logs-list-grid-detail{
            margin-left: 24px;
            margin-right: 24px;
            border: none;
            display: grid;
            grid-template-columns: 20% 80%;

            .activity-logs-list-grid-detail-text{
                padding-top: 4px;
                padding-bottom: 4px;
               
                font-size: 14px;
                font-family: $fontRegular;
                color: #888;
            }
        }

        .activity-logs-list-grid-detail-divider{
            border-bottom: 1px solid #888;
        }
    }
}