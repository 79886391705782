
@import "../App.scss";

.contact-us-main{
    .modal-dialog {
        max-width: 60% !important;
        width: 60% !important;
        margin-bottom: 5% !important;
        padding-bottom: 5%;
        @include media-breakpoint-down(xl){
            max-width: 60% !important;
            width: 60% !important;
        }
        @include media-breakpoint-down(md){
            width: 100% !important;
            max-width: 100% !important;
            margin-left: auto;
            margin-right: auto;
        }
    }


    .contact-us-control{
        display: flex;
        flex-direction: column;
        padding: 24px;

        .contact-us-title{
            font-size: 15px;
            color: $text-color;
            font-family: $fontRegular;
            margin-left: auto;
            margin-right: auto;
        }

        .contact-us-id-candidate{
            font-size: 20px;
            font-family: $fontMedium;
            color: var(--primary-red);
            margin-left: auto;
            margin-right: auto;
        }

        .contact-us-choose-text{
            font-size: 17px;
            margin-top: 24px;
            color: $text-color;
            font-family: $fontMedium;
            margin-left: auto;
            margin-right: auto;
        }

        .contact-us-choose-grid-control{
            margin-top: 24px;
            display: grid;
            grid-template-columns: repeat(4, 25%);

            @include media-breakpoint-down(md){
                grid-template-columns: 50% 50%;
            }

          
            .contact-us-grid-item{
                cursor: pointer;
                width: 97%;
                margin-left: auto;
                margin-right: auto;
                min-height: 200px;
                border-radius: 16px;
                border: 3px solid $border-input;
                padding: 16px;
                display: flex;
                flex-direction: column;

                &:hover{
                    background-color: var(--primary-red);
                }

                &:hover .contact-us-grid-icon-control .contact-us-grid-icon{
                    color: white;
                }

                &:hover .contact-us-grid-icon-control .contact-us-grid-text{
                    color: $text-white-color;
                }
                &:hover .contact-us-grid-icon-control .contact-us-grid-text-sub{
                    color: $text-white-color;
                }
                
            @include media-breakpoint-down(sm){
                margin-top: 16px;
                min-height: 150px;
            }

                .contact-us-grid-icon-control{
                    margin:auto;
                    display: flex;
                    flex-direction: column;

                    .contact-us-grid-icon{
                        margin-left: auto;
                        margin-right: auto;
                        font-size: 70px;
                        color: var(--primary-red);

                        @include media-breakpoint-down(sm){
                           font-size: 40px;
                        }
            
                    }

                    .contact-us-grid-text{
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 16px;
                        font-size: 18px;
                        font-family: $fontMedium;
                        color: $text-color;

                        @include media-breakpoint-down(sm){
                            font-size: 14px;
                        }
            
                    }

                    .contact-us-grid-text-sub{
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 16px;
                        font-size: 14px;
                        text-decoration: underline;
                        font-family: $fontRegular;
                        color: $text-color;

                        @include media-breakpoint-down(sm){
                            font-size: 12px;
                        }
            
                    }
                }
            }
           
        }
        .contact-us-close-text{
            cursor: pointer;
            text-decoration: underline;
            margin-left: auto;
            margin-right: auto;
            margin-top: 36px;
            font-size: 18px;
            font-family: $fontMedium;
            color: $text-color;
        }
    }
   
  
}