@import "../../App.scss";



.search-main-edit-mode {
    grid-template-columns: 30% 70% !important;

    @include media-breakpoint-down(xl) {
        grid-template-columns: 35% 65% !important;
    }

    @include media-breakpoint-down(lg) {
        grid-template-columns: 35% 65% !important;
    }

    @include media-breakpoint-down(md) {
        grid-template-columns: 100% !important;
    }
}

.search-main-edit-mode-result-null {

    width: 100%;
    grid-template-columns: 100% !important;
    min-height: 100vh;

    @include media-breakpoint-down(sm) {
        width: 99%;
        margin-left: auto;
        margin-right: auto;
        min-height: auto;
        height: auto;
    }

}

.search-main-control {
    width: 100vw;
    height: 94vh;
    padding: 1%;
    display: grid;
    grid-template-columns: 25% 75%;
    position: relative;

    @include media-breakpoint-down(xl) {
        grid-template-columns: 25% 75%;
    }

    @include media-breakpoint-down(md) {
        grid-template-columns: 100%;
    }


    .result-cart-import {
        position: absolute;
        margin-bottom: 16px;
        z-index: 99;
        margin-left: 16px;
        bottom: 0;
        margin-top: auto;

        @include media-breakpoint-down(md){
            top: 0;
            margin-top: 8px;
            margin-left: 8px;
        }


        .result-cart-icon-control {
            cursor: pointer;
            width: 80px;
            height: 80px;
            display: flex;
            border-radius: 50%;
            background-color: var(--primary-red);
            position: relative;

            @include media-breakpoint-down(sm) {
                width: 40px;
                height: 40px;
            }

            .result-cart-icon {
                font-size: 40px;
                color: $text-white-color;
                margin: auto;


                @include media-breakpoint-down(sm) {
                    font-size: 16px;
                }
            }

            .result-cart-badge-control {
                position: absolute;
                right: 0;
                margin-left: auto;
                margin-top: 12px;
                margin-right: 12px;
                background-color: red;
                width: 20px;
                border: none;
                height: 20px;
                display: flex;
                border-radius: 50%;

                @include media-breakpoint-down(sm) {
                    width: 15px;
                    height: 15px;
                    margin-top: 6px;
                    margin-right: 6px;
                }


            .result-cart-badge-text {
                font-size: 14px;
                font-family: $fontMedium;
                color: $text-white-color;
                margin: auto;

                @include media-breakpoint-down(sm) {
                    font-size: 9px;
                }
            }
        }
    }
}


.search-main-loading-control {
    width: 100%;
    height: 90vh;
    display: flex;

    .search-main-loading-main {
        margin: auto;
        display: flex;
        flex-direction: column;

        .search-main-loading-icon {
            width: 50px;
            height: 50px;
            object-fit: contain;
            margin-left: auto;
            margin-right: auto;
        }

        .search-main-loading-text {
            font-size: 16px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 36px;
            color: $text-color;
            font-family: $fontMedium;
        }
    }
}


}