@import "../../App.scss";

.contact-us {

    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;

    .contact-us-text-control{
        display: flex;
        flex-direction: column;
        margin: auto;
    }
    .contact-us-text{
        text-align: center;
        font-size: 17px;
        color: var(--text-color);
        font-family: $fontRegular;
       
    }

    .contact-us-text-link{
        cursor: pointer;
        text-align: center;
        font-size: 17px;
        color: var(--primary-blue);
        font-family: $fontRegular;

        &:hover{
            text-decoration: underline;
        }
       
    }

    .contact-us-text-preview{
        margin-top: 36px;
        text-align: center;
        font-size: 17px;
        color: var(--text-color);
        font-family: $fontRegular;
       
    }

}