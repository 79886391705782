@import "../App.scss";

.history-apply-modal{

    .history-apply-profile-img{
        width: 35px;
        height: 35px;
        object-fit: contain;
        border-radius: 50%;
        margin-right: 8px;
    }
    .history-apply-profile-name{
        font-size: 16px;
        font-family: $fontMedium !important;
        color: var(--text-color)
    }

    .history-apply-exp-control{
        display:  flex;
        flex-direction: column;

        .history-apply-exp-icon{
            width: 16px;
            height: 16px;
            object-fit: contain;
            margin-right: 8px;
        }
        .history-apply-exp-title{
            font-size: 14px;
            font-family: $fontMedium !important;
            color: var(--text-color)
        }

        .history-apply-divider{
            width: 100%;
            height: 1px;
            background-color: #c9c9c9;
            margin-top: 4px;
            margin-bottom: 4px;
        }

        .history-apply-exp-content{
            padding-top: 12px;
            padding-bottom: 12px;
            border-bottom: 1px solid #c9c9c9;
          

            .history-apply-exp-content-job-title-control{
                display: flex;
                flex-direction: column;
                margin-top: auto;
                margin-bottom: auto;

                .history-apply-exp-content-job-title{
                    font-size: 14px;
                    font-family: $fontRegular;
                    color: var(--text-color);
                }
                .history-apply-exp-content-job-title-bold{
                    font-size: 14px;
                    font-family: $fontMedium;
                    color: var(--text-color);
                }
            }
        }
    }

}