@import "../../App.scss";

.loading-comp {

    width: 100%;
    height: 100vh;
    display: flex;

    .loading-control{
        display: flex;
        flex-direction: column;
        margin: auto;
        
    }

    .loading-img{
        width: 90px;
        height: 90px;
        object-fit: contain;
        margin-left: auto;
        margin-right: auto;
    }
    .loading-text{
        font-size: 24px;
        font-family: $fontMedium;
        color: var(--text-color);
        text-align: center;
    }
}