@import "../../../App.scss";


.input-search-main {
    width: 98%;
    display: flex;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8px;

    @include media-breakpoint-down(md){
        height: fit-content;
        width: 100%;        
    
    }

    .input-search-box-filter-control{
        display: flex;
        font-family: $fontRegular;
        flex-direction: row;

        .MuiTypography-root{
            font-family: $fontRegular;
            font-size: 13px;
        }
        .Mui-checked{
            color: var(--primary-red);
        }
    }
    .input-search-box-edit-control{
        display: flex;
        height: auto;
        width: 100%;
        @include media-breakpoint-down(md){
            height: auto;
            min-height: 40px;
            margin-left: auto;
            margin-right: auto;
            padding-top: 8px;
        }
    }
    .input-search-box-control{
        box-shadow: $shadow-box;
        display: flex;
        height: 100%;
        border-radius: 14px;
        padding: 16px 12px;
        width: 100%;
        @include media-breakpoint-down(md){
            height: auto;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            padding: 8px 12px;
        }
    }

    .input-search-control {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: fit-content;
        .input-search-view-header-control {
            display: flex;
            width: 100%;

            .input-search-view-header-text-control {
                font-size: 14px;
                margin-top: auto;
                cursor: pointer;
                margin-bottom: auto;
                width: 100%;
                text-align: center;
                font-family: $fontMedium;
                color: var(--primary-red);
                text-decoration: underline;
                

                @include media-breakpoint-down(md){
                    width: 100%;
                }
            }
        }
        .input-search-header-control {
            display: flex;

            .input-search-header-icon {
                font-size: 24px;
                color: $text-color;
                margin-top: auto;
                margin-bottom: auto;
            }

            .input-search-header-title {
                font-size: 18px;
                color: var(--primary-blue);
                margin-left: 16px;
                font-family: $fontMedium;
                margin-top: auto;
                margin-bottom: auto;
                @include media-breakpoint-down(xl){
                    font-size: 18px;
                }
                @include media-breakpoint-down(lg){
                    font-size: 18px;
                }
            }

          
        }
        .input-search-header-sub-title {
            font-size: 12px;
            margin-top: 6px;
            color: var(--primary-blue);
            font-family: $fontRegular;
        

            @include media-breakpoint-down(lg){
                font-size: 12px;
            }
        }
        .input-search-divider {
            width: 100%;
            margin-top: 6px;
            margin-bottom: 6px;
            border: 2px solid var(--primary-red);
        }

        .input-search-result-control{
            display: flex;
            flex-direction: column;
            border-radius: 14px;
            box-shadow: 0px 0px 2px 2px var(--primary-soft-red);
            background-color: white;
            padding: 16px;
            margin-top: 5px;
            margin-bottom: 5px;
           

            @include media-breakpoint-down(md){
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
              
            }
            @include media-breakpoint-down(sm){
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                padding: 9px;
                margin-top: 4px;
                width: 98%;
                margin-left: auto;
                margin-right: auto;
            }

            .input-search-result-input-value-flex{
                flex-wrap: wrap;
                display: flex;
            }
            .input-search-result-switch{
                margin-left: auto;
                margin-top: auto;
                margin-bottom: auto;
            }
            .input-search-result-header{
                font-size: 13px;
                color: $text-color;
                font-family: $fontMedium;
                margin-top: auto;
                margin-bottom: auto;

                @include media-breakpoint-down(md){
                    display: block;
                    width: 100%;
                }

                @include media-breakpoint-down(sm){
                    font-size: 12px;
                }
            }
            .input-search-result-title{
                font-size: 14px;
                color: $text-color;
                font-family: $fontRegular;

                @include media-breakpoint-down(md){
                    display: none;
                }
            }

            .input-search-result-value{
                width: fit-content;
                border-radius: 12px;
                color: $text-color;
                padding: 6px 12px;
                min-height: 31px;
                background-color: var(--primary-soft-red);
                margin-top: 6px;
                text-align: left;
                border: none;
                font-size: 13px;
                font-family: $fontMedium;

                @include media-breakpoint-down(md){
                    margin-right: 4px;
                }
                
                @include media-breakpoint-down(sm){
                    margin-right: 4px;
                    font-size: 11px;
                    min-height: 24px;
                }
            }

            .input-search-result-divider{
                margin-top: 8px;
                margin-bottom: 8px;
                border: 2px solid white;

                @include media-breakpoint-down(md){
                    display: none;
                }
            }

            .input-search-input-edit-header-control{
                display: flex;
            }
            .input-search-input-edit-button-close-mobile{
                font-size: 18px;
                color: var(--primary-red);
                margin-left: auto;
            }
            .input-search-input-edit-button-mobile{
                display: none;

                @include media-breakpoint-down(md){
                    display: flex;
                    margin-left: auto;
                    text-decoration: underline;
                    color: var(--primary-red);
                    font-size: 14px;
                    margin-top: 16px;
                    font-family: $fontMedium;
                    margin-right: auto;
                }
            }

            .input-search-result-button-edit{
                background-color: $text-white-color;
                width: 100%;
                height: 35px;
                font-size: 14px;
                font-family: $fontMedium;
                color: var(--primary-red);
                margin-top: 12px;
                border: 1px solid var(--primary-red);
                border-radius: 8px;
 

                @include media-breakpoint-down(md){
                    display: none;
                }
            }
        }

        .input-search-border-number-control {
            display: flex;
            margin-top: 12px;


            .input-search-border-number {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                color: white;
                display: flex;
                
                background-color: var(--primary-red);

                @include media-breakpoint-down(lg){
                    width: 20px;
                    height: 20px;
                }
            }

            .input-search-border-number-text {
                font-family: $fontMedium;
                font-size: 16px;
                color: $text-white-color;
                margin: auto;

                @include media-breakpoint-down(lg){
                    font-size: 14px;
                }
            }

            .input-search-border-icon-edit{
                font-size: 24px;
                margin-left: auto;
                color: var(--primary-red);
                margin-top: auto;
                margin-bottom: auto;
                @include media-breakpoint-down(lg){
                    font-size: 17px;
                }
            }

            .input-search-border-number-title{
                font-size: 16px;
                color: $text-color;
                font-family: $fontMedium;
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 16px;

                @include media-breakpoint-down(lg){
                    font-size: 14px;
                }
            }
        }

        .input-search-item-control{
            display: flex;
            width: 100%;
            flex-direction: column;
            margin-top: 16px;
            padding-bottom: 16px;

          

            .MuiInputLabel-root {
                font-size: 12px;
            }

            .input-search-item-title{
                font-size: 14px;
                font-family: $fontMedium;
                color: var(--text-color);
            }

            .input-search-item-form{
                width: 100%;
                border-radius: 8px;
                font-size: 16px;
                height: 35px;
                margin-bottom: 4px;
                @include media-breakpoint-down(md){
                    height: 41px;
                    font-size: 14px;
                }
               
            }
            .input-search-item-form::placeholder {
                font-family: $fontRegular; /* Change "Your Font" to your desired font family */
                font-size: 12px; /* Change to your desired font size */
                /* You can also specify other styles like color, font-weight, etc. */
              }
            .input-search-error-text{
                font-size: 14px;
                color: $red-color;
                font-family: $fontRegular;
                margin-top: 6px;
            }

            .input-search-clear-text{
                margin-left: auto;
                font-size: 16px;
                cursor: pointer;
                text-decoration: underline;
                font-family: $fontRegular;
                margin-top: 12px;
            }

            .input-search-button-control{
                display: flex;

                @include media-breakpoint-down(md){
                    flex-direction: column;
                }
            }

            .input-search-button-submit{
                background-color: var(--button-primary-color);
                width: 200px;
                height: 41px;
                font-size: 16px;
                color: $text-white-color;
                margin-top: 12px;
                border: none;

                @include media-breakpoint-down(lg){
                    width: 45%;
                }

                @include media-breakpoint-down(sm){
                    width: 100%;
                }
            }

            .input-search-button-submit-quick{
                background-color: white;
                width: 200px;
                height: 41px;
                font-size: 16px;
                margin-left: 12px;
                border: 1px solid var(--primary-red);
                color: var(--primary-red);
                margin-top: 12px;
                @include media-breakpoint-down(lg){
                    width: 45%;
                }

                @include media-breakpoint-down(md){
                    margin-left: 0px;
                }

                @include media-breakpoint-down(sm){
                    width: 100%;
                    margin-top: 12px;
                }
            }
        }
        .input-search-step-2-title{
            font-size: 18px;
            font-family: $fontMedium;
            color: $text-color;
            margin-top: 16px;

            @include media-breakpoint-down(md){
                font-size: 14px;
            }
        }

        .input-search-step-2-other-skill{
            margin-top: 18px;

            .MuiOutlinedInput-root{
                height: fit-content !important;
            }

            .MuiOutlinedInput-root{
                height: 51px;
            }
        }

        .input-search-step-2-loading-control{
            margin-top: 52px;
            display: flex;
            flex-direction: column;
            margin: auto;

            .input-search-step-2-loading-progress{
                width: 30px;
                height: 30px;
                object-fit: contain;
                margin-left: auto;
                margin-right: auto;
            }

            .input-search-step-2-loading-text{
                font-size: 15px;
                color: $text-gray-color;
                font-family: $fontRegular;
                margin-left: auto;
                margin-right: auto;
                margin-top: 16px;
            }
        }
        .input-search-step-2-suggest-text-control{
            display: block;
            font-size: 12px;

            .input-search-step-2-suggest-icon{
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 2px;
                margin-right: 2px;
                color: var(--primary-red);
            }
            .input-search-step-2-suggest-text{
                margin-top: auto;
                margin-bottom: auto;
             
            }
        }

        .input-search-step-2-tag-list-control{
            display: inline-flex;
            flex-wrap: wrap;
            width: 100%;

           
        
            .input-search-step-2-tag-highlight{
                cursor: pointer;
                width: auto;
                min-height: 31px;
                margin-left: 4px;
                margin-right: 4px;
                padding: 0px 12px;
                margin-top: 8px;
                border: 1px solid var(--primary-blue);
                border-radius: 24px;
                background-color: var(--primary-blue);
                display: flex;

               
                .input-search-step-2-text{
                    font-size: 12px;
                    color: $text-white-color;
                    font-family: $fontRegular;
                    margin: auto;

                    @include media-breakpoint-down(lg){
                        font-size: 13px;
                    }
                    @include media-breakpoint-down(md){
                        font-size: 12px;
                    }
                 
                }

                .input-search-step-2-icon{
                    font-size: 14px;
                    color: $text-white-color;
                    padding-left: 24px;
                    margin-left: auto;
                    margin-top: auto;
                    margin-bottom: auto;
                }
            }
            .input-search-step-2-tag{
                cursor: pointer;
                width: auto;
                height: auto;
                min-height: 31px;
                margin-left: 4px;
                margin-right: 4px;
                padding: 0px 12px;
                margin-top: 8px;
                border: 1px solid $text-blue-color;
                border-radius: 24px;
                background-color: white;
                display: flex;

                &:hover{
                    background-color: var(--primary-blue);
                }
                &:hover .input-search-step-2-text{
                    color: $text-white-color;
                }
                &:hover .input-search-step-2-icon{
                    color: $text-white-color;
                }

                @include media-breakpoint-down(xl){
                    min-height: 31px;
                }
                .input-search-step-2-text{
                    font-size: 12px;
                    color: $text-color;
                    font-family: $fontRegular;
                    margin: auto;
                    @include media-breakpoint-down(xl){
                        font-size: 13px;
                    }
                    @include media-breakpoint-down(md){
                        font-size: 12px;
                    }
                }

                .input-search-step-2-icon{
                    font-size: 14px;
                    color: $text-color;
                    padding-left: 24px;
                    margin-left: auto;
                    margin-top: auto;
                    margin-bottom: auto;
                }
            }
            .input-search-button-submit{
                background-color: var(--button-primary-color);
                width: 100%;
                height: 41px;
                font-size: 16px;
                color: $text-white-color;
                font-family: $fontMedium;
                margin-top: 12px;
                border: none;
            }
        }
    }
}