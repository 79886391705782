@import "../node_modules/bootstrap/scss/bootstrap.scss";
$fontBold: "LINESeedSansTH_He";
$fontRegular: "LINESeedSansTH_Rg";
$fontMedium: "LINESeedSansTH_Bd";
$fontLight: "LINESeedSansTH_Th";

$primary-color: #E96D5D;
$primary-soft-color:  #ffbfbf;
$text-blue-color: #112644;
$secondary-color: #EF4123;
$red-color: #EF4123;
$button-primary-color: #4A90E2;
$link-color: #0b4ca8;
$button-share-color: #0f784c;
$text-color: #000;
$text-gray-color: #6e6e6e;
$text-white-color: #ffffff;
$disabled-color: #797979;
$blue-line: #B4DEFA;
$header-color: #333333;
$guest-bg: #797979;
$bg-color: #F1F6FE;
$border-line: #3b3b3b;
$border-input: #c6c6c6;
$shadow-box: 0px 0px 4px #112644;


body{
    font-family: $fontRegular !important;
    background-color: white;
    
}

.MuiInputBase-input {
    font-family: $fontRegular !important;
}
.MuiFormLabel-root{
    font-family: $fontRegular !important;
}
.MuiChip-label{
    font-family: $fontMedium !important;
}
::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.searching-load{
    width: 200px;
    height: 200px;
    object-fit: contain;
}

.bg-light{
    background-color: var(--header-color) !important;
}

.searching-text{
    font-size: 16px;
    font-family: $fontMedium;
    margin-top: 4px;
}

.link-default{
    font-size: 16px;
    font-family: $fontRegular;
    color: var(--button-primary-color);
    text-decoration: underline;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}


@font-face {
    font-family: $fontBold;
    src: local("LINESeedSansTH_He"), url(../public/fonts/LINESeedSansTH_He.ttf) format("truetype");
}

@font-face {
    font-family: $fontMedium;
    src: local("LINESeedSansTH_Bd"), url(../public/fonts/LINESeedSansTH_Bd.ttf) format("truetype");
}
@font-face {
    font-family: $fontRegular;
    src: local("LINESeedSansTH_Rg"), url(../public/fonts/LINESeedSansTH_Rg.ttf) format("truetype");
}
@font-face {
    font-family: $fontLight;
    src: local("LINESeedSansTH_Th"), url(../public/fonts/LINESeedSansTH_Th.ttf) format("truetype");
}
