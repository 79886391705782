@import "../App.scss";

.history-jobs-modal{
    .history-jobs-header{
        font-size: 16px;
        font-family: $fontMedium !important;
        color: var(--text-color)
    }

    .history-jobs-loading-control{
        height: 200px;
        width: 100%;
        display: flex;
        flex-direction: column;

        .history-jobs-loading-main{
            margin: auto;
            display: flex;
            flex-direction: column;

            .history-jobs-loading-progress{
                width: 30px;
                height: 30px;
                object-fit: contain;
                margin-left: auto;
                margin-right: auto;
            }

            .history-jobs-loading-text{
                font-size: 14px;
                font-family: $fontRegular;
                color: var(--text-color);
                margin-left: auto;
                margin-right: auto;
                margin-top: 16px;
            }
        }
    }

    .history-jobs-timeline-control{
        display: grid;
        grid-template-columns: 10% 90%;

        .history-jobs-timeline-info{
            display: flex;
            flex-direction: column;
            margin-left: 14px;

            .history-jobs-timeline-info-job-title{
                font-size: 14px;
                color: black;
                font-family: $fontMedium;

                @include media-breakpoint-down(sm){
                    font-size: 12px;
                }
            }
            .history-jobs-timeline-info-job-company{
                font-size: 14px;
                color: black;
                font-family: $fontRegular;
                @include media-breakpoint-down(sm){
                    font-size: 12px;
                }
            }
            .history-jobs-timeline-info-job-date{
                font-size: 12px;
                line-height: 1.5em;
                margin-top: 4px;
                margin-bottom: 4px;
                color: #4c4c4c;
                font-family: $fontRegular;
                @include media-breakpoint-down(sm){
                    font-size: 11px;
                }
            }

            .history-jobs-timeline-info-job-desc{
                font-size: 12px;
                color: black;
                font-family: $fontRegular;
                overflow-wrap: break-word;
                @include media-breakpoint-down(sm){
                    font-size: 10px;
                }
            }

            .history-jobs-timeline-info-job-divider{
                width: 100%;
                height: 2px;
                margin-top: 4px;
                margin-bottom: 4px;
                background-color: #c7c7c7;
            }
        }


        .history-jobs-timeline-dot{

            display: flex;
            flex-direction: column;

            .dots{
                width: 15px;
                height: 15px;
                background-color: var(--button-primary-color);
                border-radius: 50%;
                margin-left: auto;
                margin-right: auto;
            }
            .dots-gray{
                width: 15px;
                height: 15px;
                background-color: #888;
                border-radius: 50%;
                margin-left: auto;
                margin-right: auto;
            }
            .line{
                width: 2px;
                height: 100%;
                margin-left: auto;
                margin-right: auto;
                background-color: #c7c7c7;
            }
        }
    }
}