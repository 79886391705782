
@import "../../App.scss";

.check-login{

    display: flex;
    width: 100vw;
    height: 100vh;

    .check-login-control{
        display: flex;
        margin: auto;
        flex-direction: column;

        .check-login-loading{
            width: 100px;
            height: 100px;
            object-fit: contain;
        }

        .check-login-text{
            font-size: 16px;
            color: var(--text-color);
            text-align: center;
            font-family: $fontMedium;
        }
    }
}